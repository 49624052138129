<template>
    <div class="authority common-page">
        <PocCardTable>
            <template slot="headerTitle" v-if="accountSelectionListShow">
                <div class='hl_header'>
                    <div class="hl_breadCrumb hl_headerLeft" >
                        <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                            <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                            <el-breadcrumb-item>监控预警</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="hl_headerRight">
                        <el-button type="primary" size="small" @click="create">创建监控预警</el-button>
                        <el-popconfirm
                            title="确定删除吗？"
                            @confirm="batchDel"
                        >
                            <el-button type="success" class="setWarnning" size="small" slot="reference">批量删除</el-button>
                        </el-popconfirm>
                        <el-button type="primary" size="small" @click="showBatchStatus">批量修改状态</el-button>
                    </div>
                </div>
                <div class="materialHead">
                    <div class="materialHBtns clearfix">
                        <el-form :inline="true" ref="" class="demo-form-inline fL">
                            <el-form-item label="" prop="">
                                <el-input v-model="name" placeholder="名称"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="">
                                <el-select clearable placeholder="投放状态" v-model="status">
                                    <el-option
                                        v-for="(item,i) in statusList"
                                        :key="i"
                                        :label="item.text"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <el-button type="primary" class="newQuery" size="small" @click="search" >查询</el-button>
                        <el-button type="info" size="small" plian @click="reset">重置</el-button>
                    </div>
                </div>
                <div class="materiaContent">
                    <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%;cursor: pointer;"
                        v-loading="loading"
                        fit
                        @selection-change="handleSelectionChange">
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column
                            align='center'
                            :width="150"
                            v-for="(item,index) in titleList" :key="index"
                            :prop="item.prop"
                            :label="item.name"
                            show-overflow-tooltip>

                            <template v-if='item.prop === "adPlanList"' v-slot="scope">
                                <div style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: flex-start; gap: 4px">
                                    <el-tag
                                        v-for="plan in scope.row.adPlanList"
                                        :key="plan.adPlanId"
                                        style="margin: 2px">
                                        {{ plan.adPlanName }}
                                    </el-tag>
                                </div>
                            </template>

                            <template v-else-if='item.prop === "conditionRuleList"' v-slot="scope">
                                <div style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: flex-start; gap: 4px">
                                    <el-tag
                                        v-for="(rule, ruleIndex) in scope.row.conditionRuleList"
                                        :key="ruleIndex"
                                        type="warning"
                                        size="small">
                                        {{ getLabelByValue(rule.conditionField) }} {{ rule.conditionOperator }} {{ rule.conditionValue }}
                                    </el-tag>
                                </div>
                            </template>

                            <template v-else-if='item.prop === "status"' v-slot="scope">
                                <el-switch
                                    @change="changeStatus(scope.row)"
                                    v-model="scope.row.status"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                                </el-switch>

<!--                                <span v-if="scope.row.status === 1">开启</span>
                                <span v-else>关闭</span>-->
                            </template>

                            <template v-else-if="item.prop === 'type'" v-slot="scope">
                                <span v-if="scope.row.type === 1">账户</span>
                                <span v-else>计划</span>
                            </template>

                            <template v-else-if="item.prop === 'objectType'" v-slot="scope">
                                <span v-if="scope.row.objectType === 1">按状态</span>
                                <span v-else-if="scope.row.objectType === 2">自定义</span>
                            </template>

                            <template v-else-if="item.prop === 'objectStatus'" v-slot="scope">
                                <span v-if="scope.row.objectStatus === 1">全部</span>
                                <span v-else-if="scope.row.objectStatus === 2">开启中的计划</span>
                                <span v-else-if="scope.row.objectStatus === 3">暂停的计划</span>
                            </template>

                            <template v-else-if="item.prop === 'executeTimeType'" v-slot="scope">
                                <span v-if="scope.row.executeTimeType === 1">每天</span>
                                <span v-else-if="scope.row.executeTimeType === 2">每半小时</span>
                                <span v-else-if="scope.row.executeTimeType === 3">每周</span>
                                <span v-else>每月</span>
                            </template>

                            <template v-else-if="item.prop === 'conditionSet'" v-slot="scope">
                                <span v-if="scope.row.conditionSet === 1">满足所有条件</span>
                                <span v-else>满足任一条件</span>
                            </template>

                            <template v-else-if="item.prop === 'executeDate'" v-slot="scope">
                                <div
                                    v-if="[3, 4].includes(scope.row.executeTimeType) && scope.row.executeDate"
                                    style="display: flex; flex-wrap: wrap; gap: 4px"
                                >
                                    <el-tag
                                        v-for="date in scope.row.executeDate.split(',')"
                                        :key="date"
                                    >
                                        {{ scope.row.executeTimeType === 3 ? '周' + date : date + '号' }}
                                    </el-tag>
                                </div>
                            </template>

                            <template v-else-if="item.prop === 'executeTime'" v-slot="scope">
                                <div
                                    v-if="scope.row.executeTime"
                                    style="display: flex; flex-wrap: wrap; gap: 4px"
                                >
                                    <el-tag
                                        v-for="time in scope.row.executeTime.split(',')"
                                        :key="time"
                                    >
                                        {{ timeOptions.find(item => item.value === time)?.label || time  }}
                                    </el-tag>
                                </div>
                            </template>

                            <template v-else-if='item.prop === "senderName"' v-slot="scope">
                                <div style="display: flex; flex-wrap: wrap; flex-direction: column; align-items: flex-start; gap: 4px">
                                    <el-tag
                                        v-for="name in (scope.row.senderName.split(','))"
                                        :key="name"
                                        style="margin: 2px">
                                        {{ name }}
                                    </el-tag>
                                </div>
                            </template>

                        </el-table-column>
                        <el-table-column
                            fixed = 'right'
                            align="center"
                            width='340px'
                            :show-overflow-tooltip="true"
                            label="操作">
                            <template v-slot="scope">
                                <el-button class="solid-tag-success" plain size="mini" @click="edit(scope.row.id)" >编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="footer_paging">
                        <div class="footer_totalNum">
                            <div class="footerPaging">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="page"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="pageCount"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="this.totalRow">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </PocCardTable>

        <el-dialog
            title="批量修改状态"
            :visible.sync="batchStatusVisible"
            width="500px"
            @closed="handleStatusDialogClose"
        >
            <el-form
                ref="batchStatusFormRef"
                :model="batchStatusForm"
                :rules="batchStatusRules"
                label-width="80px"
            >
                <el-form-item label="新状态" prop="status">
                    <el-select
                        v-model="batchStatusForm.status"
                        placeholder="请选择状态"
                        style="width: 100%"
                        clearable
                    >
                        <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="batchStatusVisible = false">取 消</el-button>
                <el-button
                    type="primary"
                    :loading="statusSubmitting"
                    @click="confirmBatchStatus"
                >确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
    components: {},
    data() {
        const generateTimeOptions = () => {
            const options = []
            for (let i = 0; i < 24; i++) {
                options.push({
                    value: i.toString(),
                    label: `${i.toString().padStart(2, '0')}:00`
                })
                options.push({
                    value: (i + 0.5).toString(),
                    label: `${i.toString().padStart(2, '0')}:30`
                })
            }
            return options
        }

        return {
            ownerId: Number(this.$route.query.id),
            ownerName: this.$route.query.name,
            selectIds: [],
            timeOptions: generateTimeOptions(),
            loading: false,
            statusList: [
                {text:'关闭',id:'0'},
                {text:'开启',id:'1'},
            ],
            titleList:[
                {name:'名称',prop:'name'},
                {name:'类型',prop:'type'},
                {name:'条件规则列表',prop:'conditionRuleList'},
                {name:'条件设定',prop:'conditionSet'},
                {name:'状态',prop:'status'},
                {name:'执行时间类型',prop:'executeTimeType'},
                {name:'执行日期',prop:'executeDate'},
                {name:'执行时间',prop:'executeTime'},
                {name:'广告计划列表',prop:'adPlanList'},
                {name:'id',prop:'id'},
                {name:'托管对象',prop:'objectType'},
                {name:'托管对象状态',prop:'objectStatus'},
                {name:'广告主id',prop:'ownerId'},
                {name:'广告主名称',prop:'ownerName'},
                // {name:'发送人IDs',prop:'sender'},
                {name:'发送人姓名',prop:'senderName'},
                // {name:'当前登录用户id',prop:'userId'},
                // {name:'当前登录用户姓名',prop:'userName'},
            ],//title名称
            conditionOptions: [
                { label: '消耗', value: 'costFormat' },
                { label: '曝光量', value: 'viewSumFormat' },
                { label: '点击量', value: 'clickSumFormat' },
                { label: '下载量', value: 'downloadSumFormat' },
                { label: '点击均价', value: 'cpcFormat' },
                { label: '下载均价', value: 'costPerDownloadFormat' },
                { label: '点击率', value: 'ctrFormat' },
                { label: '下载率', value: 'downloadRatioFormat' },
                { label: '预算达线率', value: 'budgetRateFormat' },
                { label: '激活量(z)', value: 'activeSumzFormat' },
                { label: '激活均价(z)', value: 'costPerActivezFormat' },
                { label: '新增激活量(z)', value: 'activeNewSumzFormat' },
                { label: '新增激活均价(z)', value: 'costPerActiveNewzFormat' },
                { label: '注册量(z)', value: 'registerSumzFormat' },
                { label: '注册均价(z)', value: 'costPerRegisterzFormat' },
                { label: '首次拉活量(z)', value: 'reActiveSumzFormat' },
                { label: '首次拉活均价(z)', value: 'costPerReActivezFormat' },
                { label: 'APP召回量(z)', value: 'uninstallSumzFormat' },
                { label: 'APP召回均价(z)', value: 'costPerUninstallzFormat' },
                { label: '购买量(z)', value: 'appPurchaseSumzFormat' },
                { label: '购买均价(z)', value: 'costPerAppPurchasezFormat' },
                { label: '拉活量(z)', value: 'reActiveWakeUpSumzFormat' },
                { label: '拉活均价(z)', value: 'costPerReActiveWakeUpzFormat' },
                { label: '付费量(z)', value: 'paySumzFormat' },
                { label: '付费均价(z)', value: 'costPerPayzFormat' },
                { label: '关键行为数(z)', value: 'addictionNumzFormat' },
                { label: '关键行为成本(z)', value: 'addictionCostzFormat' },
                { label: '首单购买量(z)', value: 'newUserPurchaseSumzFormat' },
                { label: '首单购买均价(z)', value: 'newUserPurchaseCostzFormat' },
                { label: '加购量(z)', value: 'addCartSumzFormat' },
                { label: '授信(z)', value: 'creditNumzFormat' },
                { label: '授信成本(z)', value: 'creditCostzFormat' },
                { label: '快应用加桌量(z)', value: 'appAddDesktopNumzFormat' },
                { label: '快应用加桌成本(z)', value: 'appAddDesktopCostzFormat' },
                { label: '自定义激活留存率(z)', value: 'activeRetentRatiozFormat' },
                { label: '自定义新增激活留存率(z)', value: 'activeNewRetentRatiozFormat' }
            ],
            status:'',
            name:'',//搜索框--名称
            tableData:[],//查询有权限的所有帐号接口回显
            // 分页
            page: 1,//当前页
            pageCount: 10,//每页大小
            totalRow: 0,//总条数
            totalPage: 0,//总页数
            userId:'',//用户ID
            userName:'',
            accountSelectionListShow:true,//用户是否有权限

            batchStatusVisible: false, // 控制对话框显示
            statusSubmitting: false,   // 提交状态
            statusOptions: [           // 状态选项配置
                { label: '关闭', value: 0 },
                { label: '开启', value: 1 },
            ],
            batchStatusForm: {
                status: null            // 选中的状态值
            },
            batchStatusRules: {
                status: [
                    { required: true, message: '请选择要修改的状态', trigger: 'change' }
                ]
            }
        }
    },
    computed: {},
    mounted() {
        // this.myHeaders.Authorization = 'Bearer cf40c3ad-466e-45cd-b642-3a0992dbf357'
        this.userId = this.$sessionStorage.getItem(config.USERID) || 1
        this.userName = this.$sessionStorage.getItem(config.USERNAME)
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            const params = {
                ownerId: this.ownerId,
                userId: this.userId,
                userName: this.userName,
                name: this.name,
                status: this.status,
                pageIndex: this.page,
                pageSize: this.pageCount
            }
            $http.axios.post("/api/mi/monitor/list", params).then((response) => {
                if (response.data.code === 200) {
                    this.tableData = response.data.data.list;
                    this.pageCount = response.data.data.pageInfo.pageSize
                    this.page = response.data.data.pageInfo.pageIndex
                    this.totalRow = response.data.data.pageInfo.rowCount
                    this.totalPage = response.data.data.pageInfo.pageCount

                    this.loading = false
                }
            })
        },
        edit(id) {
            this.$router.push(
                {
                    path: "/MiAccount-management/monitorMi/" + id,
                    query: {
                        ownerId: this.ownerId,
                        ownerName: this.ownerName
                    }
                }
            );
        },
        reset() {
            this.name = ''
            this.status = ''
            this.page = 1
            this.pageCount = 10
        },
        search() {
            this.getData()
        },
        batchDel() {
            if (this.selectIds.length === 0) {
                this.$message.warning('请先选择要操作的数据')
                return false
            }

            $http.axios.post("/api/mi/monitor/batch/del", {ids: this.selectIds.join(',')}).then((response) => {
                if (response.data.code === 200) {
                    this.$message.success(response.data.msg)
                } else {
                    this.$message.warning(response.data.msg)
                }

                this.getData()
            })
        },
        showBatchStatus() {
            if (this.selectIds.length === 0) {
                this.$message.warning('请先选择要操作的数据')
                return false
            }

            this.batchStatusVisible = true
        },
        // 关闭对话框时重置表单
        handleStatusDialogClose() {
            this.$refs.batchStatusFormRef.resetFields()
        },
        async confirmBatchStatus() {
            try {
                const valid = await this.$refs.batchStatusFormRef.validate()
                if (!valid) return

                this.statusSubmitting = true

                // 调用接口
                const res = await $http.post('/api/mi/monitor/batch/status', {
                    ids: this.selectIds.join(','),
                    status: this.batchStatusForm.status
                })

                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.getData()
                    this.batchStatusVisible = false
                }
            } catch (error) {
                console.error('批量修改失败:', error)
                this.$message.error('状态更新失败，请稍后重试')
            } finally {
                this.statusSubmitting = false
            }
        },
        // 新建监控预警
        create() {
            this.$router.push(
                {
                    path: "/MiAccount-management/monitorMiCreate/",
                    query: {
                        ownerId: this.ownerId,
                        ownerName: this.ownerName
                    }
                }
            );
        },

        getLabelByValue(value) {
            const option = this.conditionOptions.find(item => item.value === value);
            return option ? option.label : null;
        },

        // 快速修改状态
        async changeStatus(row) {
            try {
                this.loading = true

                // 调用接口
                const res = await $http.post('/api/mi/monitor/batch/status', {
                    ids: row.id,
                    status: row.status
                })

                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.getData()
                }

                this.loading = false
            } catch (error) {
                console.error('批量修改失败:', error)
                this.$message.error('状态更新失败，请稍后重试')
                this.loading = false
            } finally {
                this.loading = false
            }
        },

        // 分页
        handleSizeChange(val) {
            let _this = this
            console.log(666)
            _this.pageCount = val
            // _this.$set(_this.params,'page',val)
            _this.findAll(_this.page)
        },
        handleCurrentChange(val) {
            this.page = val
            this.findAll(val)
        },
        //分页方法
        findAll (val) {
            this.page = val
            //获取数据接口（调用封装的接口）
            this.oppoAccountList(val,3,0)
        },

        //选择行
        handleSelectionChange(val){
            let selectIds = []
            val.forEach(obj=>{
                selectIds.push(obj.id)
            })
            this.selectIds = selectIds
        },
    }
}
</script>

<style lang="scss" scoped>

/* 开关开始 */
.el-switch__label{
    color:#fff !important;
    position: absolute;
}
.switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
}
.switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
}
.switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
}
.switchStyle .el-switch__label.is-active {
    display: block;
}
.switchStyle.el-switch .el-switch__core,
.el-switch .el-switch__label {
    width: 50px !important;
}
/* 开关结束 */

/* 批量操作开始 */
.allOperation{
    margin-top: 10px;
}
.allOperation .totalNum{
    margin-right: 15px;
    font-size: 13px;
    color: #606266;
}
.allOperation .totalNum i{
    font-style: initial;
}
.footerPaging{
    margin-top: 10px;
}
/* 批量操作结束 */

/* 操作按钮样式开始 */
/* .planeBtn,.changeBtn,.offBtn{
  display: inline-block;
  cursor: pointer;
} */
/* 操作按钮样式结束 */
#uploadBtn{
    display: inline-block;
}
.el-button.el-button--primary{
    margin: 0 10px 0 0!important;
}
.el-button--small{
    margin-right: 10px;
}
/* oppo和vivo的tab切换 */
.chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
}
.channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px;
    cursor: pointer;
}
.channel_vivo{
    margin-left:-1px;
}
.channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
}
.setWarnning{
    background:#FFF5EC;
    color:#F3920B;
    border:1px solid #F3920B;
    border-radius:6px;
}
.setWarnning:hover{
    background:#F3920B;
    color:#FFF5EC;
    border:1px solid #F3920B;
}
.materialHead {
    margin-top: 10px;
}
/*新版*/
::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
}
::v-deep .distribDialog .el-dialog{
    border-radius:20px;
}
.dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
}
::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
}
::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
}
::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
}
::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
}
::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
}
::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
}
::v-deep .distribDialog .el-input__suffix{
    right:65px;
}
::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
}
::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
}
.warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
}
.warnInfosList span{
    font-weight:500;
    padding-right:5px;
}
.warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
}
::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
}
::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
}
.deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
}
.deleteBtnicon{
    color:#f12323;
    font-size:20px;
}
.addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
}
.addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
}
.addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
}
.other{
    display:block;
    color:#999;
    font-size:12px;
}
.otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
}
.otherItemRight{
    margin-right:0;
}
::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
}
::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
}
.uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
}
.batchUpload{
    text-align:center;
}
.uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
}
</style>

import PublicisCommon from 'publicis-common';
import Router from '@/router';
import config from '@/api/config'; 
const { $http, $sessionStorage, PocResetMessage, $cookies } = PublicisCommon;
const { VUE_APP_BASEURL } = process.env;

/**
 * request请求拦截器
 * 1. 给每个请求的header设置token用于鉴权，token来至sessionStorage
 * 2. 为了兼容性，在同域情况下，给cookie也塞token，兼容后端逻辑
 */
$http.axios.defaults.baseURL = VUE_APP_BASEURL;
$http.axios.interceptors.request.use(request => {
  const Authorization = 'Bearer ' + $sessionStorage.getItem(config.USER_TOKEN_KEY);
  const accessUserId = $sessionStorage.getItem(config.USERID) || 1;
  const accessDeptId = $sessionStorage.getItem(config.DEPTID);
  const accessUserName = $sessionStorage.getItem(config.USERNAME);
  const accessRoles = $sessionStorage.getItem(config.ROLES);
  // request.baseURL = VUE_APP_BASEURL;
  if (Authorization) {
    request.headers[config.USER_TOKEN_KEY] = Authorization;
    const cookieAuthorization = $cookies.get(config.COOKIE_TOKEN);
    if (!cookieAuthorization || (cookieAuthorization && cookieAuthorization !== Authorization)) {
      $cookies.set(config.COOKIE_TOKEN, Authorization, (60 * 60 * 4), null, document.domain);
    }
    request.headers[config.USERID] = accessUserId;
    const cookieUserId = $cookies.get(config.COOKIE_USERID);
    if (!cookieUserId || (cookieUserId && cookieUserId !== cookieUserId)) {
      $cookies.set(config.COOKIE_USERID, cookieUserId, (60 * 60 * 4), null, document.domain);
    }
    request.headers[config.USERNAME] = accessUserName;
    const cookieUserName = $cookies.get(config.COOKIE_USERNAME);
    if (!cookieUserName || (cookieUserName && cookieUserName !== cookieUserName)) {
      $cookies.set(config.COOKIE_USERNAME, cookieUserName, (60 * 60 * 4), null, document.domain);
    }
    request.headers[config.DEPTID] = accessDeptId;
    const cookieDeptId = $cookies.get(config.COOKIE_DEPTID);
    if (!cookieDeptId || (cookieDeptId && cookieDeptId !== cookieDeptId)) {
      $cookies.set(config.COOKIE_DEPTID, cookieDeptId, (60 * 60 * 4), null, document.domain);
    }
    request.headers[config.ROLES] = accessRoles;
    const cookieRoles = $cookies.get(config.COOKIE_ROLES);
    if (!cookieRoles || (cookieRoles && cookieRoles !== cookieRoles)) {
      $cookies.set(config.COOKIE_ROLES, cookieRoles, (60 * 60 * 4), null, document.domain);
    }

  }

  return request;
});

/**
 * 响应拦截器
 * 1. 处理通用提示
 * 2. 处理未登录，跳转sso
 */
// $http.axios.interceptors.response.use((response) => {
//   let code = response.data.code || response.data.status;
//   code = parseInt(code);
//   const notLogin = (code === 999999 || code === 100000);
//   if (code > 0 && !notLogin) {
//     PocResetMessage.warning(response.data.msg);
//   }
//   if (notLogin && Router.currentRoute.name !== 'login') {
//     Router.push({ name: 'login' });
//   }
//   return response;
// });

$http.axios.interceptors.response.use((response) => {
  let code = response.data.code || response.data.status;
  let msg = response.data.msg
  code = parseInt(code);
  // console.log(code)
  // const notLogin = (code === 500 && msg === '登录状态已过期');
  const notLogin = (msg === '登录状态已过期');
  // const notLogin = (code === 500);
  if (notLogin) {
    Router.push('/')
  }
  return response;
});

export { $http };

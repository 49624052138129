<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item  :to="{ path: '/auth-management/demandOrder' }">运营管理</el-breadcrumb-item>
        <el-breadcrumb-item  :to="{ path: '/auth-management/demandOrder' }">需求工单</el-breadcrumb-item>
        <el-breadcrumb-item>上传素材</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
          <div class="setupOrderForm" v-for="(item,index) in courseInfo.daysArray" :key=index>
            <el-form ref="setupOrderform" :rules="rules" :model="setupOrderform" label-width="80px">
              <div v-for="(item,i) in uploadParams" :key="i" style="margin-bottom:30px;" ref="uploadRef">
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="需求工单">
                    <el-input :placeholder="workOrderName" :disabled="true"></el-input>
                  </el-form-item>
                </div>
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="素材标签">
                    <el-select v-model="item.materialTagNames" filterable multiple :placeholder="materialTab" @change="selectContract($event,i)">
                      <el-option
                        v-for="(item,i) in tfMaterialTabOptions"
                        :key="i"
                        :label="item.tagName"
                        :value="item.id">
                        {{item.tagName}}
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="素材名称" v-if="mtnameShow">
                    <el-input v-model="item.mtName" placeholder="请输入素材名称"></el-input>
                  </el-form-item>
                </div>
                <div class="redstar">
                  <span class="starStyle">*</span>
                  <el-form-item label="制作人员" >
                    <el-select v-model="item.clipUserId" filterable placeholder="剪辑人员" @change="changejj($event,i)">
                       <el-option
                         v-for="(item,i) in demandNameOptionsjj"
                         :key="i"
                         :label="item.name"
                         :value="item.id">
                         {{item.name}}
                       </el-option>
                     </el-select>
                   </el-form-item>
                   <el-form-item  v-if="matSpecId==60 && matSpecId==63">
                     <el-select v-model="item.photographyUserId" v-if="matSpecId!=60 && matSpecId!=63" filterable placeholder="摄影人员" @change="changesy($event,i)">
                       <el-option
                         v-for="(item,i) in demandNameOptionssy"
                         :key="i"
                         :label="item.name"
                         :value="item.id">
                         {{item.name}}
                       </el-option>
                     </el-select>
                   </el-form-item>
                   <el-form-item  v-if="matSpecId==60 && matSpecId==63">
                      <el-select v-model="item.directorUserId" v-if="matSpecId!=60 && matSpecId!=63" filterable placeholder="编导人员" @change="bdchange($event,i)">
                       <el-option
                         v-for="(item,i) in demandNameOptionsbd"
                         :key="i"
                         :label="item.name"
                         :value="item.id">
                         {{item.name}}
                       </el-option>
                     </el-select>
                   </el-form-item>
                </div>
                <el-form-item label="主题文案">
                  <el-input v-model="item.mainTitile" placeholder="请输入文案"></el-input>
                </el-form-item>
                <el-form-item label="素材内容" :prop="item.propFile">
                  <div class="picList" v-for="(item,index) in picDetail" :key="index">
                    <el-upload 
                      class="upload-demo" 
                      ref="uploadNew"
                      action
                      :http-request="(file)=>{return upload(file, i,item.name)}"
                      :on-preview="handlePreview" 
                      :on-remove="(file, fileList)=>{return handleRemove(file, fileList, i)}" 
                      :file-list="fileList[item.name]" 
                      list-type="picture" 
                      :headers="myHeaders"
                      :on-success="onSuccess5"
                      :on-error="onError"
                      :before-upload="(file, fileList)=>{return beforeAvatarUpload(file, item)}"
                      multiple
                      :limit="(item.name == 'imgs'&&Number(item.value==1))?30:Number(item.value)" 
                      :on-exceed="handleExceed">
                      <el-button class="uploadBtn" size="small" type="primary" @click="getItemPath(item)">点击上传{{btnInfo[item.name]}}</el-button>
                    </el-upload>

                  </div>
                
                  <el-dialog
                      title=""
                      :visible.sync="VideoVisible"
                      width="20%">
                        <video v-if="Video !='' " :src="Video" controlsList="nodownload" class="avatar" autoplay="autoplay" controls="controls">您的浏览器不支持视频播放</video>
                    </el-dialog>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" v-if="i>0" @click="removeRow(i)">删除素材</el-button>
                </el-form-item>
              </div>
              <el-form-item>
                <!-- <el-button type="primary" size="small" @click="submitSuccess" v-show="uploaded">保存</el-button> -->
                <el-button type="primary" size="small" @click="submitSuccess">保存</el-button>
                <el-button type="info" plain size="small" @click="cancel" v-if="closeTip==''">取消</el-button>
                <el-button type="info" plain size="small" @click="cancelTip" v-else>取消</el-button>
              </el-form-item>
            </el-form>
            <el-dialog
              title=""
              :visible.sync="tipdialogVisible"
              width="30%">
              <span><i style="font-style:normal;color:red;font-weight:700;font-size:16px;">注意：</i>如果已经上传视频，点击【取消】后再次上传同一视频会报错，请谨慎操作，如果误传，请联系管理员。</span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="tipdialogVisible = false">关闭弹框</el-button>
              </span>
            </el-dialog>
            <!-- 上传视频有重名的时候弹出的弹框 -->
            <el-dialog
              title=""
              :visible.sync="duplicateVisible"
              width="30%">
              <div class="duplicateName">
                <p>{{duplicateDate}}</p>
                <p>请删除已存在的素材，请重新上传！</p>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="duplicateVisible = false">关闭</el-button>
              </span>
            </el-dialog>
            <el-button type="primary" size="small" class="changeMaterial addMaterial" @click="addMaterialList(num)">新增素材</el-button>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
  </div>
</template>
 
<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
 
export default {
  name: 'uploadMaterial',
  data() {
    return {
      courseInfo:{daysArray:[{}]},
      tipdialogVisible:false,
      setupOrderform: {
        channelName:'',
        workOrderType:'',
        workOrderName:'',
        orderDate:'',
        demandDate:[],
        demandName:'',
        accountName:'',
        resourcesType:'',
        materialDesc:'',
        resourcesPosition:[],
        materialTab:'',
        materialLink:'',
        materialType:'',
        bdName:'',
        syName:'',
        jjName:'',
        workOrderName1:'',
        materialTab1:'',
        bdName1:'',
        file:'',
      },
      btnInfo:{'imgs':'图片','video':'视频','videoBg':'视频封底','brandLogo':'logo','videoPage':'视频封面'},
      rules: {
        file0: [{required: true, message:'请重新上传文件', trigger: 'change'}]
      },
      uploaded:false,//是否显示保存按钮，默认不显示
      workOrderName:'',
      materialTab:'',
      channelOptions:[{//渠道名称
        value: '1',
        label: 'OPPO'
      }, {
        value: '2',
        label: '今日头条'
      }, {
        value: '3',
        label: '快手'
      }],
      workOrderTypeOptions:[{//工单类型
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      demandOptions:[{//需求日期
        value: '选项1',
        label: '周一'
      }, {
        value: '选项2',
        label: '周二'
      }, {
        value: '选项3',
        label: '周三'
      }, {
        value: '选项4',
        label: '周四'
      }, {
        value: '选项5',
        label: '周五'
      }, {
        value: '选项6',
        label: '周六'
      }, {
        value: '选项7',
        label: '周日'
      }],
      demandNameOptionsbd:[],
      demandNameOptionssy:[],
      demandNameOptionsjj:[],
      accountOptions:[{//账户名称
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      resourcesOptions:[{//资源类型
        value: '选项1',
        label: 'OPPO'
      }, {
        value: '选项2',
        label: '今日头条'
      }, {
        value: '选项3',
        label: '快手'
      }],
      resourcesPoptions:[{//素材样式
        label:'banner0 1080*171 <30KB'
      },{
        label:'banner1 1080*171 <30KB'
      },{
        label:'banner2 1080*171 <30KB'
      },{
        label:'banner3 1080*171 <30KB'
      }],
      resourcesNum:'',//素材样式-数量
      resourcesTab:'',//素材样式-标签
      imgList:{},
      uploadParams:[
        {
          accountId:'',//账号id
          // copywriting:'',//描述，可不传
          channelId:',',//渠道id
          clipUserId:'',//剪辑人id//
          clipUserName:'',//剪辑人name//
          detailId:'',//子工单id
          directorUserId:'',//编导id//
          directorUserName:'',//编导name//
          mainTitile:'',//主标题//
          mtName:'',//素材名称
          makerUserId:'',//制作人id//
          makerUserName:'',//制作人name//
          materialTagIds:[],//素材标签id//
          materialTagNames:[],//素材标签name//
          photographyUserId:'',//
          photographyUserName:'',//
          specId:'',
          materialDtoUrlDtos:[
            {
              imgList: [],
              imgUrl: "",
              logoUrl: "",
              videoBgUrl: "",
              videoUrl: ""
            }
          ],
          userId:'',//登录者id
          username:'',//登录者用户名
          type:'',//回显接口中的，1图文2视频
          propFile:'file0'//区别文件
        }
      ],
      uploadParamsDto:{
        uploadDto:[
          {
            scbq:'',
            sclj:'',
            bdry:'',
            syry:'',
            jjry:'',
          }
        ]
      },
      tagListParams:{//标签列表参数
        tagType:'2',
        userId:'',
        channel:''
      },
      tfMaterialTabOptions:[],
      hxParmas:{
        id:''
      },
      hxData:{},
      fileList:[],
      myHeaders: {Authorization:''},
      upParams:[],
      editerArr:[],
      value1:[],
      materialAccountId:'',
      materialChannel:'',
      uploadId:'',
      closeTip:'',
      mmId:[],
      mmName:[],
      num:1,
      videoList:[],//视频地址列表——>判断视频是否都已上传
      Video:'',//上传视频地址
      VideoVisible:false,//视频框是否显示
      duplicateVisible:false,//有本次重名的上传视频弹框
      duplicateDate:'',//有本次重名的上传视频提示信息
      duplicateDate2:[],//服务器上传视频提示信息
      one:false,
      two:false,
      matSpecId:'',
      picDetail:[],//图片的几种情况
      successUrl1:'',
      successUrl2:'',
      successUrl3:'',
      successUrl4:'',
      mtnameShow:false,//素材名称的显示问题
      path:'',//素材请求地址
      picValue:'',
      numberValue:'',//限制的图片数量
      uploadTotalNum:0,
      uploadPath: '',
      logoUrl:'',
    }
  },
  computed: {
     
  },
  mounted() {
    this.getId()
    this.gethx()
    this.getuserChannel()
    this.getStaffDetail()
  },
  methods: {
    //弱提示
    alertMsgFn(msg,type){
      this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },
    onSubmit() {
      console.log('submit!');
    },
 
    getuserChannel(){
      let that = this
      let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
        if(response.data.data.length != 0){
          that.tagListParams.channel = response.data.data[0].id
          that.getTagList()
        }
      })
    },


    // 上传前的校验
   
    handleAvatarSuccess(res, file) {
      console.log(res)
      console.log(file)
      this.imageUrl = URL.createObjectURL(file.raw);
      //
      if (res.code !== 200) {
        this.$message.error('上传失败！')
      } else {
        this.isShowRequest = false
        this.isLoad = false
        this.modalForm.logoId = res.body.fileId
        this.modalForm.logoPath = res.body.filePath
      }
    },
    beforeAvatarUpload(file, item){
      // 校验图片格式(也可直接用 accept 属性限制如：accept="image/png,image/jpg,image/jpeg,image/gif")
    let format = item.format,size = item.size,fileWidth=item.width,fileHeight=item.height,bli=item.bli,isFormat
      format = item.format.split('/')
      format.forEach(obj=>{
        if(obj=='jpg'||obj=='png'){
          isFormat = file.type === 'image/'+obj||file.type ==='image/jpeg'
        }else{
          isFormat = file.type === 'video/'+obj
        }
      })
      // const isFormat = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/gif';
      // 校验图片大小
      let is200K
      if(size!=0){
        is200K = Math.ceil(file.size / 1024)  < size
      }else{
        is200K = true
      }
      if (!isFormat) {
        this.$message.error('上传文件只能为 '+format+' 格式!');
        return false
      // } else if (!is200K&&!size) {
      } else if (!is200K&&size) {
        this.$message.error('上传文件大小不能超过'+size+'KB!');
        return false
      } else {
        let isSize
        if(file.type == 'video/mp4'){
           isSize =  new Promise((resolve, reject) => {
            let videoElement = document.createElement('video');
            videoElement.addEventListener('loadedmetadata', function() {
              resolve({
                duration: videoElement.duration,
                width: videoElement.videoWidth,
                height: videoElement.videoHeight
              })
              //  let valid = (videoElement.videoWidth/videoElement.videoHeight).toFixed(2) == bli
            let valid = (videoElement.videoWidth/videoElement.videoHeight).toFixed(2) == 1.74
            valid ? resolve() : reject();
            })
            videoElement.src = URL.createObjectURL(file);

          }).then(
              () => {
                  return file;
              },
              () => {
                if(!bli){
                  this.$message.error('上传文件尺寸应为'+fileWidth+'*'+fileHeight);
                }else{
                  this.$message.error('上传文件尺寸限制为'+bli+'比例');
                }
                  return Promise.reject();
              },
          );
        }else{
          // 校验图片宽高大小
           isSize = new Promise((resolve, reject) => {
              const width = fileWidth;
              const height = fileHeight;
              const _URL = window.URL || window.webkitURL;
              const img = new Image();
              img.onload = () => {
                  // 限制宽高必须为 18*18 像素
                  const valid = width?img.width == width:true && height?img.height == height:true 
                  // const valid = img.width == img.height;
                  // // 限制必须为竖屏图片(宽必须小于高)
                  // const valid = img.width < img.height;
                  // // 限制必须为横屏图片(宽必须大于高)
                  // const valid = img.width > img.height;
                  console.log(img.width)
                  valid ? resolve() : reject();
              };
              img.src = _URL.createObjectURL(file);
          }).then(
              () => {
                  return file;
              },
              () => {
                if(!bli){
                  if(fileWidth!=0||fileHeight != 0){
                    this.$message.error('上传文件尺寸应为'+fileWidth+'*'+fileHeight);
                  }else{
                    return true;
                  }
                }else{
                  this.$message.error('上传文件尺寸限制为'+bli+'比例');
                }
                  return Promise.reject();
              },
          );
        }
       return isFormat && is200K && isSize
      }
      
      // return isFormat && is200K && isSize;
    },
    /**
     * 自定义上传
     */
    upload(file, i, name) {
      let _this = this, formdata = new FormData(),fileListNew = []
      fileListNew[name] = []
      _this.uploadErrorNum++
      formdata.append('file',file.file)
      $http.axios.defaults.timeout =''
      // $http.axios.post("/lcl/material/"+this.uploadPath,formdata).then(function(response) {
      $http.axios.post("/api/operationManagement/material/"+this.uploadPath,formdata).then(function(response) {
        if(response.data.code == 200) {
          fileListNew[name].push({'name':file.file.name,'url':response.data.data})
          _this.onSuccess5(response, file, fileListNew[name], i, name,)
        }else{
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
      
    },
    //上传水印
    //文件列表移除文件时的钩子
    // handleRemove(file, fileList,i) {
    //   let _this = this, btnName = {'imgs':'imgList','brandLogo':'logoUrl','videoPage':'imgUrl','videoBg':'videoBgUrl','video':'videoUrl'},name = btnName[file.raw.fileName]
    //   console.log(file)
    //   console.log(fileList)
    //   // this.$refs.setupOrderform[0].resetFields()
    //   _this.$refs.setupOrderform[0].clearValidate('file'+i);
    //   console.log(this.$refs.setupOrderform[0])
      
    //   _this.uploadParams[i].materialDtoUrlDtos.forEach((obj,index)=>{
    //     if(file.uid == obj.uid){
    //       _this.uploadParams[i].materialDtoUrlDtos.splice(index,1)
    //     }
    //   })
    //   if(fileList.length==0){
    //     _this.videoList.splice(i, 1);
    //     if(name =='imgList'){
    //       _this.uploadParams[i].materialDtoUrlDtos[i][name] = [];
    //     }else{
    //       _this.uploadParams[i].materialDtoUrlDtos[i][name] = '';
    //     }
    //   }
    // },

    handleRemove(file, fileList,i) {
      let _this = this, btnName = {'imgs':'imgList','brandLogo':'logoUrl','videoPage':'imgUrl','videoBg':'videoBgUrl','video':'videoUrl'},name = btnName[file.raw.fileName]
      console.log(file)
      console.log(fileList)
      // this.$refs.setupOrderform[0].resetFields()
      _this.$refs.setupOrderform[0].clearValidate('file'+i);
      console.log(this.$refs.setupOrderform[0])
      _this.uploadParams[i].materialDtoUrlDtos.forEach((obj,index)=>{
        if(file.uid == obj.uid){
          _this.uploadParams[i].materialDtoUrlDtos.splice(index,1)
          _this.imgList[i].splice(index,1)
        }
      })
      if(fileList.length==0){
        _this.videoList.splice(i, 1);
        if(name =='imgList'){
          if(_this.uploadParams[i].materialDtoUrlDtos[i]!=undefined){
            _this.uploadParams[i].materialDtoUrlDtos[i][name] = [];
          }
          // _this.uploadParams[i].materialDtoUrlDtos[i]['imgList'] = [];
        }else{
          if(_this.uploadParams[i].materialDtoUrlDtos[i]!=undefined){
          _this.uploadParams[i].materialDtoUrlDtos[i][name] = '';
          }
        }
      }
    },


    //点击已上传的文件链接时的钩子,获取服务器返回的数据
    handlePreview(file) {
      let that = this
      if(file.response.data){
        that.Video = file.response.data
        setTimeout(function(){
          that.VideoVisible = true
        },1000)
      }
    },
    getItemPath(item) {
      this.uploadPath = item.path
    },
    //文件上传成功时的钩子
    onSuccess5(response, file, fileList, i, name) {
      console.log(response)
      // /api/operationManagement/material/
    //  this.$refs.upload.clearFiles()
      let that = this
      if(response.data.code==200){
        file.file.fileName = name
        if(that.uploadParams[i].materialDtoUrlDtos){
          let successArr = that.uploadParams[i].materialDtoUrlDtos
          if(that.matSpecId!=60 && that.matSpecId!=63&&that.matSpecId!=30){
            // successArr.push({'imgList':})
            
            if(name == 'brandLogo'){
              
              that.logoUrl = response.data.data
            }else{
              if(that.imgList[i]==undefined){
                that.imgList[i] = []
              }
                that.imgList[i].push({'imgList':[response.data.data],'uid':file.file.uid})
            }
            
              // successArr[]=that.imgList
              // successArr[logoUrl=that.logoUrl
              if(that.imgList[i]!=undefined){
                that.imgList[i].forEach((obj,j)=>{
                  successArr[j]={'imgList':obj.imgList,'logoUrl':that.logoUrl,'uid':obj.uid}
                })
              }
              
          }else{
            successArr.forEach(function(e,i){
              if(name == 'imgs'){
                e.imgList.push(response.data.data)
              }else if(name == 'brandLogo'){
                e.logoUrl = response.data.data
              }else if(name == 'videoPage'){
                e.imgUrl = response.data.data
              }else if(name == 'videoBg'){
                e.videoBgUrl = response.data.data
              }else {
                e.videoUrl = response.data.data
              } 
            })
          }
          }
        that.closeTip = response.data.data
        that.$message({
          showClose: true,
          message: response.data.msg,
          type: 'success'
        })
        let List=[]
        that.videoList = []
       that.uploadParams.forEach((item,j)=>{
          if(item.materialDtoUrlDtos&&item.materialDtoUrlDtos.length){
            List.push(item.materialDtoUrlDtos)
          }
        })
        that.videoList=List
      }else{
        that.$refs.setupOrderform[0].validateField(that.uploadParams[i].propFile)
        console.log(that.$refs.setupOrderform[0].validateField())
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
        that.handleRemove(file, [], i)
      }
    },
    // beforeAvatarUpload(res, file){
    //   console.log(res,file)
    // },
    onError(err, file, fileList) {
      let that = this
      that.$message.error(err.msg)
    },

    handleExceed(files, fileList) {
      console.log(files)
      this.$message.warning(`当前限制选择 ${this.numberValue}  个文件，本次选择了 ${files.length} 个文件`);
    },
 
    //标签和工单回显
    gethx(){
      let that = this
      $http.axios.get("/api/operationManagement/material/touploadview?id="+that.uploadId).then(function(response) {
        if(response.data.code == 200){
          that.matSpecId = response.data.data.specId
          if(that.matSpecId == 63 || that.matSpecId == 60){
            that.mtnameShow = false
          }else{
            that.mtnameShow = true
          }
          that.workOrderName = response.data.data.demandNum
          that.materialTab = response.data.data.tagName
          that.materialType = response.data.data.materialType
          that.materialAccountId = response.data.data.accountId
          that.materialChannel = response.data.data.accountChannel
          that.mmId.push(response.data.data.tagId)
          that.mmName.push(response.data.data.tagName)
          that.uploadParams[0].materialTagIds.push(response.data.data.tagId)
          that.uploadParams[0].materialTagNames.push(response.data.data.tagName)
          that.pictureDetail()
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg || "工单异常",
            type: 'error'
          })
          that.$router.push('/auth-management/DemandOrder')
        }
      })
    },

    //根据回显接口获取图片详情
    pictureDetail(){
      let that = this
      $http.axios.get("/api/operationManagement/material/getConfig?matSpecId="+that.matSpecId).then(function(response) {
      // $http.axios.get("/lcl/material/getConfig?matSpecId="+that.matSpecId).then(function(response) {
        if(response.data.code == 200){
          console.log(response.data.data)
          that.picDetail = response.data.data
          that.path = response.data.data.path
          response.data.data.forEach(function(e,i){
            that.picValue = e.value
            if(e.name == 'imgs'){
              that.numberValue = e.value
            }
          })
          console.log(that.numberValue)
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

 
    //查询的素材标签下拉框数据
    getTagList(){
      let that = this
      that.tagListParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
      $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
        if(res.data.code == 200){
          that.tfMaterialTabOptions = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
 
    //获取人员信息
    getStaffDetail(){
      let that = this
      $http.axios.get("/api/system/user/optionselect").then(function(response) {
        that.demandNameOptionsbd = response.data
        that.demandNameOptionssy = response.data
        that.demandNameOptionsjj = response.data
      })
    },
 
    //没有重名后保存成功跳转页面
    submitSuccess(){
      let that = this
      $http.axios.post("/api/operationManagement/material/devupload",that.uploadParams).then(res=> {
        if(res.data.code == 200){
          console.log(res)
          that.$router.back()          
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //保存的时候先判断有没有重复的视频：分为1和数据库重复，2本次上传有重复视频，3和数据库和本次上传都有重复
    submitUpload(){
      let that = this
      $http.axios.post("/api/operationManagement/material/judgeName",that.uploadParams).then(res=> {
      // $http.axios.post("/lcl/material/judgeName",that.uploadParams).then(res=> {
        let judgeData = res.data.data
        if(res.data.code == 200){
          that.submitSuccess()
        } else {
          that.duplicateVisible = true
          that.duplicateDate = res.data.msg
        }
      })
    },
 
    //新增素材
    addMaterialList(){
      let that = this
      if(that.matSpecId == 63 || that.matSpecId == 60){
        that.mtnameShow = false
      }else{
        that.mtnameShow = true
      }
      that.num++
      // that.uploadParams.push({propFile:'file'+that.num,materialTagIds:that.mmId,materialTagNames:that.mmName})
      // that.uploadParams[that.num].materialDtoUrlDtos.push({imgUrl :''},{})
      that.uploadParams.push({propFile:'file'+that.num,materialTagIds:that.mmId,materialTagNames:that.mmName,materialDtoUrlDtos:[{imgList:[],imgUrl:'',logoUrl:'',videoBgUrl:'',videoUrl:''}]})
      that.rules['file'+that.num]=[{required: true, message:'请重新上传文件', trigger: 'change'}]
    },
    //删除新增素材
    removeRow(index) {
      this.num--
      if (index >= 0) {
        this.uploadParams.splice(index, 1);
        this.videoList.splice(index, 1);
        delete this.rules['file'+this.num]
      }
    },
 
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
 
    cancelTip(){
      this.tipdialogVisible = true
    },
 
    //取消
    cancel(){
      // this.$router.push('/auth-management/VideoMaterialList')
      this.$router.back()
    },
 
    //获取合同服务类型的name值
    selectContract(vId,i){
      // vID 是一个数组，循环获取name,根据id
      if (!vId && vId.length === 0) {
        return ;
      }//这里多选的时候获取name和单选不一样 单选是对象 多选是数组所以...
      let nameArr = [];
      let codeArr = [];
      let that = this;
      vId.forEach(element => {
        let obj = that.tfMaterialTabOptions.find((item)=>{
          return item.id === element;
        });
        if (obj) {
          nameArr.push(obj.tagName);
          codeArr.push(obj.id);   
        }
      });
      this.uploadParams[i].materialTagNames=nameArr;
    },
 
    //获取编导详情
    bdchange(vId,i){
      let obj = {};
      obj = this.demandNameOptionsbd.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      console.log(obj)
      this.uploadParams[i].directorUserName = obj.name
    },
 
    //获取摄影人员
    changesy(vId,i){
      let obj = {};
      obj = this.demandNameOptionssy.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      console.log(obj)
      this.uploadParams[i].photographyUserName = obj.name
      // this.uploadParams[i].demandNameOptionsjj = obj.name
    },
 
    //剪辑
    changejj(vId,i){
      let obj = {};
      obj = this.demandNameOptionsjj.find((item)=>{
          return item.id === vId;//筛选出匹配数据
      });
      this.uploadParams[i].clipUserName = obj.name
      this.uploadParams[i].makerUserName = obj.name
      this.uploadParams[i].makerUserId = vId

      //回显或着上一页带过来的参数必填项
      this.uploadParams[i].accountId = this.materialAccountId
      // this.uploadParams[i].channelId = this.materialChannel
      this.uploadParams[i].channelId = 3
      this.uploadParams[i].userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.uploadParams[i].username = this.$sessionStorage.getItem(config.USERNAME)
      this.uploadParams[i].specId = this.matSpecId
      // this.uploadParams[i].type = 2
      this.uploadParams[i].detailId = this.uploadId
      if(this.matSpecId == 60 || this.matSpecId == 63){
        this.uploadParams[i].type = 2
      }else{
        this.uploadParams[i].type = 1
      }
      
      // this.uploadParams[i].accountId = this.materialAccountId
      // this.uploadParams[i].channelId = this.materialChannel
      // this.uploadParams[i].userId = this.$sessionStorage.getItem(config.USERID) || 1
      // this.uploadParams[i].username = this.$sessionStorage.getItem(config.USERNAME)
      // this.uploadParams[i].type = 2
      // this.uploadParams[i].detailId = this.uploadId
    },
 
 
 
 
 
  }
}
</script>
 
<style lang="scss" scoped>
  .el-form-item{
    display:block!important;
  }
  .setupOrderForm{
    width:50%;
  }
  .resourcesResult,.resourceForm,.resourcesDelete{
    display:inline-block;
    vertical-align:middle;
    margin-right:1%;
  }
  .resourcesResult{
    width:50%;
    padding-right:5px;
    box-sizing:border-box
  }
  .resourceForm{
    width:45%;
  }
  /* .resourcesDelete{
    width:5%;
  } */
  .resourcesNum{
    width:60px;
    margin-right:5px;
  }
  .resourcesTab{
    width:100px;
  }
  .changeMaterial{
    font-size:14px;
    position:absolute;
    top:150px;
    right:50px;
    cursor:pointer;
  }
  .el-input{
    width: auto;
  }
  .el-select{
    margin: 0 5px;
  }
  .avatar{
    width: 100%;
    height:90%;
  }
  .el-dialog__wrapper .el-dialog{
    background-color: transparent;
  }
  .duplicateName p {
    margin:8px 0;
  }
  .duplicateName p span{
    margin:5px 0;
  }
  .duplicateName p span i{
    font-style:normal;
    color:red;
  }
  .uploadBtn{
    margin:10px 0;
  }
  .redstar{
    position: relative;
  }
  .starStyle{
    position:absolute;
    color:red;
    font-size:10px;
  }
</style>
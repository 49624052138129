import { render, staticRenderFns } from "./monitorMi.vue?vue&type=template&id=5b8ca610&scoped=true"
import script from "./monitorMi.vue?vue&type=script&lang=js"
export * from "./monitorMi.vue?vue&type=script&lang=js"
import style0 from "./monitorMi.vue?vue&type=style&index=0&id=5b8ca610&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8ca610",
  null
  
)

export default component.exports
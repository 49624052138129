<template>
    <div class="authority common-page">
        <PocCardTable>
            <template slot="headerTitle">
                <div class='hl_header' style="margin-bottom: 20px">
                    <div class="hl_breadCrumb hl_headerLeft" >
                        <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                            <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                            <el-breadcrumb-item>账户选择</el-breadcrumb-item>
                            <el-breadcrumb-item>监控预警</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ id ? id : '创建' }}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>

                <div class="materiaContent">
                    <el-tabs v-model="activeTab">
                    <!-- 账户Tab -->
                        <el-tab-pane label="账户" name="account">
                            <el-form ref="accountForm" :model="accountForm" label-width="100px">
                                <div class="form-section">
                                    <div class="form-header"></div>

                                    <el-form-item label="托管名称" required>
                                        <el-input v-model="accountForm.name" placeholder="请输入托管名称" style="width: 300px"/>
                                    </el-form-item>

                                    <el-form-item label="托管动作">
                                        <div class="condition-block">
                                            <div class="condition-title">条件设定</div>
                                            <div class="condition-item">
                                                <el-radio-group v-model="accountForm.conditionSet">
                                                    <el-radio :label="1">满足所有条件</el-radio>
                                                    <el-radio :label="2">满足任一条件</el-radio>
                                                </el-radio-group>
                                            </div>

                                            <div v-for="(condition, index) in accountForm.conditionRuleList" :key="index" class="condition-item">
                                                <el-select v-model="condition.conditionField" style="width: 120px">
                                                    <el-option
                                                        v-for="option in conditionOptions"
                                                        :key="option.value"
                                                        :label="option.label"
                                                        :value="option.value"
                                                    />
                                                </el-select>
                                                <el-select v-model="condition.conditionOperator" style="width: 110px">
                                                    <el-option label=">=" value=">="/>
                                                    <el-option label="=" value="="/>
                                                    <el-option label="<=" value="<="/>
                                                </el-select>
                                                <el-input-number v-model="condition.conditionValue" :min="0" :precision="2"/>
                                                <el-button type="text" @click="removeAccountCondition(index)">删除</el-button>
                                            </div>
                                            <el-button type="text" @click="addAccountCondition">+ 添加条件</el-button>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="执行时间">
                                        <div class="time-config">
                                            <!-- 执行周期类型 -->
                                                <el-radio-group v-model="accountForm.executeTimeType" @change="changeTimeType">
                                                    <el-radio :label="1" class="radio-item">每天</el-radio>
                                                    <el-radio :label="2" class="radio-item">每半小时</el-radio>
                                                    <el-radio :label="3" class="radio-item">每周</el-radio>
                                                    <el-radio :label="4" class="radio-item">每月</el-radio>
                                                </el-radio-group>

                                            <!-- 每周选择 -->
                                            <div v-if="accountForm.executeTimeType === 3" class="config-section">
                                                <div class="section-title">选择周几</div>
                                                <el-select
                                                    v-model="accountForm.executeDate"
                                                    multiple
                                                    placeholder="请选择星期"
                                                >
                                                    <el-option
                                                        v-for="day in 7"
                                                        :key="day"
                                                        :label="`周${['一','二','三','四','五','六','日'][day-1]}`"
                                                        :value="day.toString()"
                                                    />
                                                </el-select>
                                            </div>

                                            <!-- 每月选择 -->
                                            <div v-else-if="accountForm.executeTimeType === 4" class="config-section">
                                                <div class="section-title">选择日期</div>
                                                <el-select
                                                    v-model="accountForm.executeDate"
                                                    multiple
                                                    placeholder="请选择日期"
                                                >
                                                    <el-option
                                                        v-for="date in 31"
                                                        :key="date"
                                                        :label="`${date}号`"
                                                        :value="date.toString()"
                                                    />
                                                </el-select>
                                            </div>

                                            <div
                                                v-if="[1, 3, 4].includes(accountForm.executeTimeType)"
                                                class="config-section"
                                            >
                                                <div class="section-title">选择执行时间</div>
                                                <el-select
                                                    v-model="accountForm.executeTime"
                                                    multiple
                                                    filterable
                                                    allow-create
                                                    default-first-option
                                                    placeholder="请选择或输入时间"
                                                >
                                                    <el-option
                                                        v-for="time in timeOptions"
                                                        :key="time.value"
                                                        :label="time.label"
                                                        :value="time.value"
                                                    />
                                                </el-select>
                                            </div>

                                            <!-- 每半小时提示 -->
                                            <div v-if="accountForm.executeTimeType === 2" class="config-section">
                                                <div class="section-title">执行频率</div>
                                                <div class="tip">系统将自动每半小时执行一次检查</div>
                                            </div>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="发送人" required>
                                        <el-select v-model="accountForm.sender" value-key="userId" multiple>
                                            <el-option
                                                v-for="user in users"
                                                :key="user.userId"
                                                :label="user.string"
                                                :value="user.userId"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </el-tab-pane>

                        <!-- 广告计划Tab -->
                        <el-tab-pane label="广告计划" name="campaign">
                            <el-form ref="campaignForm" :model="campaignForm" label-width="100px">
                                <div class="form-section">
                                    <div class="form-header"></div>

                                    <el-form-item label="托管名称" required>
                                        <el-input v-model="campaignForm.name" placeholder="请输入托管名称" style="width: 300px"/>
                                    </el-form-item>

                                    <el-form-item label="托管对象">
                                        <el-radio-group v-model="campaignForm.objectType" style="width: 100%">
                                            <el-radio :label="1">按状态</el-radio>
                                            <el-radio :label="2">自定义</el-radio>
                                        </el-radio-group>

                                        <div class="dual-column-layout" v-if="campaignForm.objectType === 2">
                                            <div class="campaign-select">
                                                <div class="select-header">
                                                    <span>广告计划</span>
                                                    <el-checkbox v-model="selectAll" @change="handleSelectAll">全选</el-checkbox>
                                                </div>

                                                <!-- 滚动容器 -->
                                                <div class="scroll-container" v-loading="isLoading">
                                                    <el-checkbox-group
                                                        v-model="campaignForm.adPlanList"
                                                        class="checkbox-group"
                                                    >
                                                        <el-checkbox
                                                            v-for="campaign in campaigns"
                                                            :key="campaign.adPlanId"
                                                            :label="campaign"
                                                            :checked="campaign.isChecked"
                                                            class="campaign-checkbox"
                                                        >
                                                            {{ campaign.adPlanName }}
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                            </div>

                                            <!-- 右侧已选广告计划 -->
                                            <div class="selected-container campaign-select">
                                                <div class="select-header">
                                                    <span>已选广告（{{ campaignForm.adPlanList.length }} 项）</span>
                                                    <el-button
                                                        type="text"
                                                        @click="clearAllSelected"
                                                        :disabled="!campaignForm.adPlanList.length"
                                                    >清空</el-button>
                                                </div>

                                                <div class="scroll-container">
                                                    <div v-if="!campaignForm.adPlanList.length" class="empty-tip">暂无选中广告计划</div>
                                                    <div v-else class="selected-list">
                                                        <el-tag
                                                            v-for="item in campaignForm.adPlanList"
                                                            :key="item.adPlanId"
                                                            closable
                                                            @close="removeSelected(item)"
                                                            class="selected-tag"
                                                        >
                                                            {{ item.adPlanName }}
                                                        </el-tag>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div v-else-if="campaignForm.objectType === 1" class="campaign-select">
                                            <div class="select-header">
                                                <span>选择状态</span>
                                            </div>
                                            <el-select v-model="campaignForm.objectStatus" placeholder="请选择状态">
                                                <el-option label="全部" :value="1"/>
                                                <el-option label="开启中的计划" :value="2"/>
                                                <el-option label="暂停中的计划" :value="3" />
                                            </el-select>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="托管动作">
                                        <div class="condition-block">
                                            <div class="condition-title">条件设定</div>
                                            <div class="condition-item">
                                                <el-radio-group v-model="campaignForm.conditionSet">
                                                    <el-radio :label="1">满足所有条件</el-radio>
                                                    <el-radio :label="2">满足任一条件</el-radio>
                                                </el-radio-group>
                                            </div>

                                            <div v-for="(condition, index) in campaignForm.conditionRuleList" :key="index" class="condition-item">
                                                <el-select v-model="condition.conditionField" style="width: 120px">
                                                    <el-option
                                                        v-for="option in conditionOptions"
                                                        :key="option.value"
                                                        :label="option.label"
                                                        :value="option.value"
                                                    />
                                                </el-select>
                                                <el-select v-model="condition.conditionOperator" style="width: 110px">
                                                    <el-option label=">=" value=">="/>
                                                    <el-option label="=" value="="/>
                                                    <el-option label="<=" value="<="/>
                                                </el-select>
                                                <el-input-number v-model="condition.conditionValue" :min="0" :precision="2"/>
                                                <el-button type="text" @click="removeCampaignCondition(index)">删除</el-button>
                                            </div>
                                            <el-button type="text" @click="addCampaignCondition">+ 添加条件</el-button>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="执行时间">
                                        <div class="time-config">
                                            <!-- 执行周期类型 -->
                                            <el-radio-group v-model="campaignForm.executeTimeType" @change="changeTimeType">
                                                <el-radio :label="1" class="radio-item">每天</el-radio>
                                                <el-radio :label="2" class="radio-item">每半小时</el-radio>
                                                <el-radio :label="3" class="radio-item">每周</el-radio>
                                                <el-radio :label="4" class="radio-item">每月</el-radio>
                                            </el-radio-group>

                                            <!-- 每周选择 -->
                                            <div v-if="campaignForm.executeTimeType === 3" class="config-section">
                                                <div class="section-title">选择周几</div>
                                                <el-select
                                                    v-model="campaignForm.executeDate"
                                                    multiple
                                                    placeholder="请选择星期"
                                                >
                                                    <el-option
                                                        v-for="day in 7"
                                                        :key="day"
                                                        :label="`周${['一','二','三','四','五','六','日'][day-1]}`"
                                                        :value="day.toString()"
                                                    />
                                                </el-select>
                                            </div>

                                            <!-- 每月选择 -->
                                            <div v-else-if="campaignForm.executeTimeType === 4" class="config-section">
                                                <div class="section-title">选择日期</div>
                                                <el-select
                                                    v-model="campaignForm.executeDate"
                                                    multiple
                                                    placeholder="请选择日期"
                                                >
                                                    <el-option
                                                        v-for="date in 31"
                                                        :key="date"
                                                        :label="`${date}号`"
                                                        :value="date.toString()"
                                                    />
                                                </el-select>
                                            </div>

                                            <div
                                                v-if="[1, 3, 4].includes(campaignForm.executeTimeType)"
                                                class="config-section"
                                            >
                                                <div class="section-title">选择执行时间</div>
                                                <el-select
                                                    v-model="campaignForm.executeTime"
                                                    multiple
                                                    filterable
                                                    allow-create
                                                    default-first-option
                                                    placeholder="请选择或输入时间"
                                                >
                                                    <el-option
                                                        v-for="time in timeOptions"
                                                        :key="time.value"
                                                        :label="time.label"
                                                        :value="time.value"
                                                    />
                                                </el-select>
                                            </div>

                                            <!-- 每半小时提示 -->
                                            <div v-if="campaignForm.executeTimeType === 2" class="config-section">
                                                <div class="section-title">执行频率</div>
                                                <div class="tip">系统将自动每半小时执行一次检查</div>
                                            </div>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="发送人" required>
                                        <el-select v-model="campaignForm.sender" value-key="userId" multiple>
                                            <el-option
                                                v-for="user in users"
                                                :key="user.userId"
                                                :label="user.string"
                                                :value="user.userId"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="footer-btn">
                    <el-button size="small" @click="handleCancel">取消</el-button>
                    <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
                </div>
            </template>
        </PocCardTable>
    </div>
</template>

<script>
import {$http} from "@/api/http";
import config from "@/api/config";

export default {
    data() {
        // 生成时间选项 0, 0.5, 1, 1.5 ... 23.5
        const generateTimeOptions = () => {
            const options = []
            for (let i = 0; i < 24; i++) {
                options.push({
                    value: i.toString(),
                    label: `${i.toString().padStart(2, '0')}:00`
                })
                options.push({
                    value: (i + 0.5).toString(),
                    label: `${i.toString().padStart(2, '0')}:30`
                })
            }
            return options
        }

        return {
            // 路径传递参数
            id: this.$route.params.id,
            ownerId: this.$route.query.ownerId,
            ownerName: this.$route.query.ownerName,

            conditionOptions: [
                { label: '消耗', value: 'costFormat' },
                { label: '曝光量', value: 'viewSumFormat' },
                { label: '点击量', value: 'clickSumFormat' },
                { label: '下载量', value: 'downloadSumFormat' },
                { label: '点击均价', value: 'cpcFormat' },
                { label: '下载均价', value: 'costPerDownloadFormat' },
                { label: '点击率', value: 'ctrFormat' },
                { label: '下载率', value: 'downloadRatioFormat' },
                { label: '预算达线率', value: 'budgetRateFormat' },
                { label: '激活量(z)', value: 'activeSumzFormat' },
                { label: '激活均价(z)', value: 'costPerActivezFormat' },
                { label: '新增激活量(z)', value: 'activeNewSumzFormat' },
                { label: '新增激活均价(z)', value: 'costPerActiveNewzFormat' },
                { label: '注册量(z)', value: 'registerSumzFormat' },
                { label: '注册均价(z)', value: 'costPerRegisterzFormat' },
                { label: '首次拉活量(z)', value: 'reActiveSumzFormat' },
                { label: '首次拉活均价(z)', value: 'costPerReActivezFormat' },
                { label: 'APP召回量(z)', value: 'uninstallSumzFormat' },
                { label: 'APP召回均价(z)', value: 'costPerUninstallzFormat' },
                { label: '购买量(z)', value: 'appPurchaseSumzFormat' },
                { label: '购买均价(z)', value: 'costPerAppPurchasezFormat' },
                { label: '拉活量(z)', value: 'reActiveWakeUpSumzFormat' },
                { label: '拉活均价(z)', value: 'costPerReActiveWakeUpzFormat' },
                { label: '付费量(z)', value: 'paySumzFormat' },
                { label: '付费均价(z)', value: 'costPerPayzFormat' },
                { label: '关键行为数(z)', value: 'addictionNumzFormat' },
                { label: '关键行为成本(z)', value: 'addictionCostzFormat' },
                { label: '首单购买量(z)', value: 'newUserPurchaseSumzFormat' },
                { label: '首单购买均价(z)', value: 'newUserPurchaseCostzFormat' },
                { label: '加购量(z)', value: 'addCartSumzFormat' },
                { label: '授信(z)', value: 'creditNumzFormat' },
                { label: '授信成本(z)', value: 'creditCostzFormat' },
                { label: '快应用加桌量(z)', value: 'appAddDesktopNumzFormat' },
                { label: '快应用加桌成本(z)', value: 'appAddDesktopCostzFormat' },
                { label: '自定义激活留存率(z)', value: 'activeRetentRatiozFormat' },
                { label: '自定义新增激活留存率(z)', value: 'activeNewRetentRatiozFormat' }
            ],
            timeOptions: generateTimeOptions(),
            isLoading: false,
            activeTab: 'account',
            selectAll: false,
            accountForm: {
                name: '',
                conditionRuleList: [],
                conditionSet: '',
                executeTimeType: '',
                executeDate: '',
                executeTime: '',
                sender: []
            },
            campaignForm: {
                name: '',
                objectType: '',
                objectStatus: '',
                adPlanList: [],
                conditionRuleList: [],
                conditionSet: '',
                executeTimeType: '',
                executeDate: '',
                executeTime: '',
                sender: []
            },
            campaigns: [],
            users: [],

            adPlanListTmp: [],
        }
    },
    mounted() {
        this.getDetail()
        this.getUser()
        this.getCampaigns()
    },
    methods: {
        addAccountCondition() {
            this.accountForm.conditionRuleList.push({conditionField: '', conditionOperator: '', conditionValue: ''})
        },
        removeAccountCondition(index) {
            this.accountForm.conditionRuleList.splice(index, 1)
        },
        addCampaignCondition() {
            this.campaignForm.conditionRuleList.push({conditionField: '', conditionOperator: '', conditionValue: ''})
        },
        removeCampaignCondition(index) {
            this.campaignForm.conditionRuleList.splice(index, 1)
        },

        // 广告系列全选
        handleSelectAll(val) {
            this.campaignForm.adPlanList = val
                ? this.campaigns.map(item => item)
                : []
        },
        // 移除单个已选项
        removeSelected(item) {
            const index = this.campaignForm.adPlanList.findIndex(
                i => i.adPlanId === item.adPlanId
            )
            if (index > -1) {
                this.campaignForm.adPlanList.splice(index, 1)
            }
        },
        // 清空所有已选项
        clearAllSelected() {
            this.campaignForm.adPlanList = []
            this.selectAll = false
        },

        changeTimeType() {
            const form = this.activeTab === 'account' ? this.accountForm : this.campaignForm;
            form.executeDate = '';
            form.executeTime = '';
        },

        // 通知人
        getUser() {
            $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId=5").then((response) => {
                if (response.data.code === 200) {
                    this.users = (response.data.data || []).map(item => ({ userId: item.userId, string: item.string }));
                }
            })
        },

        // 获取当天年月日
        getCurrentDate() {
            let now = new Date();
            let year = now.getFullYear();
            let month = String(now.getMonth() + 1).padStart(2, '0');
            let day = String(now.getDate()).padStart(2, '0');
            return year + "-" + month + "-" + day;
        },

        // 获取广告系列
        getCampaigns() {
            this.isLoading = true
            const params = {
                userId: this.$sessionStorage.getItem(config.USERID) || 1,
                userName: this.$sessionStorage.getItem(config.USERNAME),
                deptId: this.$sessionStorage.getItem(config.DEPTID),
                ownerId: this.ownerId,
                ownerName: this.ownerName,
                pageSize: 1000,
                pageIndex: 1,
                startDate: this.getCurrentDate(),
                endDate: this.getCurrentDate()
            }

            $http.axios.post("/api/mi/adPlan/V2/list", params).then((response) => {
                if (response.data.code === 200) {
                    this.isLoading = false

                    if (this.adPlanListTmp.length) {
                        const selectedAdPlanIds = new Set(
                            (this.adPlanListTmp || []).map(item => Number(item.adPlanId))
                        );

                        this.campaigns = (response.data.data.list || []).map(item => ({
                            adPlanId: item.id,
                            adPlanName: item.name,
                            isChecked: selectedAdPlanIds.has(item.id)
                        }));
                    } else {
                        this.campaigns = (response.data.data.list || []).map(item => ({
                            adPlanId: item.id,
                            adPlanName: item.name
                        }));
                    }
                }
            })
        },

        // 获取详情
        getDetail() {
            if (this.id) {
                $http.axios.post("/api/mi/monitor/detail", {id: this.id}).then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.data.type === 1) {
                            this.activeTab = 'account'

                            this.accountForm = {
                                ...this.accountForm,
                                ...response.data.data
                            };

                            this.accountForm.sender = response.data.data.sender ? response.data.data.sender.split(',') : ''
                            this.accountForm.executeDate = response.data.data.executeDate ? response.data.data.executeDate.split(',') : ''
                            this.accountForm.executeTime = response.data.data.executeTime ? response.data.data.executeTime.split(',') : ''
                        } else {
                            this.activeTab = 'campaign'

                            const {adPlanList, ...ret} = response.data.data

                            this.campaignForm = {
                                ...this.campaignForm,
                                ...ret
                            };

                            this.campaignForm.sender = response.data.data.sender ? response.data.data.sender.split(',') : ''
                            this.campaignForm.executeDate = response.data.data.executeDate ? response.data.data.executeDate.split(',') : ''
                            this.campaignForm.executeTime = response.data.data.executeTime ? response.data.data.executeTime.split(',') : ''

                            if (this.campaigns.length) {
                                const selectedAdPlanIds = new Set(
                                    (response.data.data.adPlanList || []).map(item => Number(item.adPlanId))
                                );

                                this.campaigns.map(item => item.isChecked = selectedAdPlanIds.has(item.adPlanId))
                            } else {
                                this.adPlanListTmp = response.data.data.adPlanList || []
                            }
                        }
                    }
                })
            }
        },

        handleSubmit() {
            let params = {};
            if (this.activeTab === 'account') {
                params = {...params, ...this.accountForm}
                params.type = 1
            } else {
                params = {...params, ...this.campaignForm}
                params.adPlanList = params.adPlanList.map(({ isChecked, ...rest }) => rest);
                params.type = 2
            }

            if (!params.sender.length || !params.name) {
                this.$message.error('发送人和托管名称不能为空！')
                return
            }

            params.executeDate = params.executeDate ? params.executeDate.join(',') : ''
            params.executeTime = params.executeTime ? params.executeTime.join(',') : ''

            // 单独处理通知人
            const matchedUsers = this.users.filter(user =>
                params.sender.includes(user.userId)
            );
            // 将userId和string分别转换为逗号分隔的字符串
            params.sender = matchedUsers.map(user => user.userId).join(",");
            params.senderName = matchedUsers.map(user => user.string).join(",");

            params.userId = this.$sessionStorage.getItem(config.USERID) || 1
            params.userName = this.$sessionStorage.getItem(config.USERNAME)
            params.ownerId = this.ownerId
            params.ownerName = this.ownerName

            if (this.id) {
                // 编辑
                params.id = this.id

                $http.axios.post("/api/mi/monitor/edit", params).then((response) => {
                    if (response.data.code === 200) {
                        this.$message.success(response.data.msg)
                        this.$router.back();
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })
            } else {
                // 创建
                $http.axios.post("/api/mi/monitor/add", params).then((response) => {
                    if (response.data.code === 200) {
                        this.$message.success(response.data.msg)
                        this.$router.back();
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })
            }
        },
        handleCancel() {
            this.$router.back();
        }
    }
}
</script>

<style scoped>
.time-config {
    width: 100%;
}

.config-section {
    margin-bottom: 20px;
}

.section-title {
    color: #606266;
    font-size: 14px;
    margin: 10px 0;
    font-weight: bold;
}

.tip {
    color: #909399;
    font-size: 12px;
    margin-top: 5px;
}

.form-header {
    font-size: 16px;
    font-weight: bold;
    color: #303133;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebeef5;
}

.condition-block {
    width: 500px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    padding: 15px;
}

.condition-title {
    color: #909399;
    margin-bottom: 10px;
}

.condition-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.campaign-select {
    width: 500px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    padding: 15px;
    margin-top: 10px;
}

.select-header {
    width: 500px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* 开关开始 */
.el-switch__label{
    color:#fff !important;
    position: absolute;
}
.switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
}
.switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
}
.switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
}
.switchStyle .el-switch__label.is-active {
    display: block;
}
.switchStyle.el-switch .el-switch__core,
.el-switch .el-switch__label {
    width: 50px !important;
}
/* 开关结束 */

.allOperation .totalNum{
    margin-right: 15px;
    font-size: 13px;
    color: #606266;
}
.allOperation .totalNum i{
    font-style: initial;
}
/*新版*/
::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
}
::v-deep .distribDialog .el-dialog{
    border-radius:20px;
}

::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
}
::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
}
::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
}
::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
}
::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
}
::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
}
::v-deep .distribDialog .el-input__suffix{
    right:65px;
}
::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
}
::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
}
.warnInfosList span{
    font-weight:500;
    padding-right:5px;
}
.warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
}
::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
}
.addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
}
::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
}
::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
}

.selected-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dual-column-layout {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.scroll-container {
    height: 400px; /* 根据需求调整高度 */
    overflow-y: auto;
}

/* 滚动容器 */
.scroll-container {
    height: 400px; /* 根据需求调整高度 */
    overflow-y: auto;
}

/* 复选框组优化 */
.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* 单个复选框样式 */
.campaign-checkbox {
    margin-right: 0;
    transition: background-color 0.3s;
}

.campaign-checkbox:hover {
    background-color: #f5f7fa;
}

/* 滚动条美化 */
.scroll-container::-webkit-scrollbar {
    width: 6px;
}

.scroll-container::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 3px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background: #c0c4cc;
    border-radius: 3px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background: #909399;
}
</style>
